import Alpine from 'alpinejs'

export class TCPA {

    tcpaText = ''
    tcpaDescription = null
    seenTCPAId = null
    recordTCPA = false
    partnersList = null

    set({ tcpaText, tcpaDescription, seenTCPAId, recordTCPA, partnersList }) {
        Object.assign(this, { tcpaText, tcpaDescription, seenTCPAId, recordTCPA, partnersList })
    }

    /**
     * @return {int|null}
     */
    getRecordableTcpaId() {

        if (!this.recordTCPA) {
            return null
        }

        return this.seenTCPAId

    }

    registerModal() {

        const modal = Alpine.store('qv').modal

        modal.pushModal('partners_list', this.partnersList)

        const content = `
            <div id="tcpa-content">
                <label for="leadid_tcpa_disclosure">
                    <input id="leadid_tcpa_disclosure" type="hidden">
                    ${ this.description ? `<div class="mb-5 text-gray-500 text-sm text-center" data-cy="tcpa-description">${ this.description }</div>` : '' }
                    <div class="mb-5 text-gray-500 text-sm text-center" data-cy="tcpa-text" id="tcpa-text">
                        ${ this.tcpaText }
                    </div>
                </label>
            </div>
        `

        modal.pushModal('tcpa-modal', content)

    }

}

import Alpine from 'alpinejs'

export default Chat => (activeStep, welcomeMessage, tcpaData) => ({
    chat: Alpine.store('qv').chat,
    activeStep,
    welcomeMessage,
    tcpaData,
    showTcpaModal: false,

    submitForm() {
        Chat.controller.submitForm()
    },

    init() {

        Chat.controller.showWelcomeMessage(this.activeStep, this.welcomeMessage)
        Alpine.store('qv').tcpa.set(this.tcpaData)

    },
})

import Alpine from 'alpinejs'
import { ActivePage } from '../../enums/ActivePage'
import replaceNavigation from './replaceNavigation'
import State from './State'

let preloadCache
let webFormStarted = false

const cachedSteps = []

const WebFormController = new class {

    get cachedSteps() {
        return cachedSteps
    }

    async preload() {

        if (!Alpine.store('qv').navigation.hasGroups) {
            return true
        }

        if (typeof preloadCache !== 'undefined') {
            return true
        }

        preloadCache = await fetch(appendQuery(`/fast/web-form/${ Alpine.store('state').visitorId }`))
            .then(response => response.json())
            .then(data => data.data.view)

        return true

    }

    async start() {

        if (!Alpine.store('qv').navigation.hasGroups) {

            this.endForm()

            return

        }

        await this.preload()

        if (!webFormStarted) {

            Alpine.store('qv').runOnWebFormStartHook()
            webFormStarted = true

        }

        /**
         * If this is an inline web form and start() is called again
         * it means the visitor clicked on a start web form cta
         */
        if (State.step === 1 && (Alpine.store('qv').navigation.hasInlineWebForm || Alpine.store('qv').navigation.isInlineWithRedirect)) {

            showFirstStep()

            return

        }

        State.incrementStep()

        if (Alpine.store('qv').navigation.hasInlineWebForm || Alpine.store('qv').navigation.isInlineWithRedirect) {
            return
        }

        showFirstStep()

    }

    async submitAnswer(offset, form) {

        State.canSubmit = false
        State.formErrors = {}
        State.loading = true
        Alpine.store('qv').runBeforeStepSubmissionHook(form)

        const response = await fetch(appendQuery(`/fast/web-form/${ Alpine.store('state').visitorId }/${ offset }`), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(form),
        })

        const data = await response.json()

        if (data.success === false) {

            State.formErrors = data.error.errors
            State.loading = false
            State.canSubmit = true

            return

        }

        if (!Alpine.store('qv').navigation.hasInlineWebForm && Alpine.store('qv').navigation.activePage !== ActivePage.WebFormChat) {
            Alpine.store('qv').navigation.activePage = ActivePage.WebFormChat
        }

        Alpine.store('qv').runAfterStepSubmissionHook(form)
        Alpine.store('qv').stepHistory.storeAnsweredStepOffset(offset)

        // Should redirect
        if (data.code === 2004) {

            Alpine.store('qv').navigation.redirectTo(data.url)

            return

        }

        // Should redirect via modal
        if (data.code === 2005) {

            Alpine.store('qv').modal.openModalFromRaw(data.modal, { canClose: false })

            return

        }

        // No more groups
        if (data.code === 2001) {

            Alpine.store('qv').runBeforeFormSubmissionHook(form)
            Alpine.store('qv').runAfterFormSubmissionHook(form)
            State.loading = false
            State.canSubmit = true
            this.endForm()

            return

        }

        State.loading = false
        State.canSubmit = true
        State.webFormContent = data.data.view
        State.incrementStep()
        cachedSteps[ State.step ] = State.webFormContent
        Alpine.store('qv').navigation.goToStep(State.step, replaceNavigation(State.step))

    }

    async updateVisitorData(data) {

        await fetch(`/update-visitor-data/${ Alpine.store('state').visitorId }`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'x-csrf-token': Alpine.store('qv').getCsrfToken(),
            },
            body: JSON.stringify(data),
        })

    }

    endForm() {

        if (Alpine.store('qv').navigation.bypassLoadingPage) {
            Alpine.store('qv').thankYou.controller.start()
        } else {
            Alpine.store('qv').loading.controller.start()
        }

    }

}()

function appendQuery(path) {
    return Alpine.store('qv').helpers.appendQuery(path)
}

function showFirstStep() {

    Alpine.store('qv').navigation.goToStep(State.step, replaceNavigation(State.step))
    State.webFormContent = preloadCache
    Alpine.store('qv').navigation.activePage = ActivePage.WebFormChat
    cachedSteps[ State.step ] = preloadCache
    preloadCache = undefined

}

export default WebFormController

import Alpine from 'alpinejs'
import { ChatRole } from '../../enums/ChatRoleEnum'
import { ApiCode } from '../../enums/ApiCodeEnum'
import { ActivePage } from '../../enums/ActivePage'
import { beforeChatConversationUpdated, afterChatConversationUpdated } from '../../DomainHooks'
import State from './State'

let preloadCache

const ChatController = new class {

    async preload() {

        if (!Alpine.store('qv').navigation.hasGroups) {
            return true
        }

        if (typeof preloadCache !== 'undefined') {
            return true
        }

        preloadCache = await fetch(`/fast/chat/${ Alpine.store('state').visitorId }`)
            .then(response => response.json())
            .then(data => data.data.view)

        return true

    }

    async start() {

        await this.preload()
        State.htmlContent = preloadCache
        Alpine.store('qv').navigation.goToChat()
        Alpine.store('qv').navigation.activePage = ActivePage.WebFormChat
        preloadCache = undefined

    }

    randomKey() {
        return Math.floor(Math.random() * 1000000)
    }

    pollForMessages(fromMessageId) {

        clearInterval(this.pollIntervalId)

        this.pollIntervalId = setInterval(async() => {

            const response = await fetch(`/fast/visitor-conversation-data/${ Alpine.store('state').visitorId }/?fromMessageId=${ fromMessageId }`, {
                headers: {
                    accept: 'application/json',
                    'content-type': 'application/json',
                    'x-csrf-token': Alpine.store('qv').getCsrfToken(),
                },
            })
                .then(response => {

                    if (response.status === ApiCode.NoContent) {

                        return {
                            success: true,
                            code: ApiCode.NoContent,
                        }

                    }

                    return response.json()

                })

            if (response.success === true) {

                // If there is no content, we want to keep polling
                if (response.code === ApiCode.NoContent) {
                    State.pollCounter++
                } else {

                    const newMessages = response.data.messages
                    const latestAnswer = response.data.latestVisitorAnswerData

                    afterChatConversationUpdated(State.formData, latestAnswer)
                    this.handleIncomingMessages(newMessages)

                    if (response.data.completedAt) {
                        State.chatCompleted = true
                    }

                    // We can stop polling now and reset everything for the next message
                    clearInterval(this.pollIntervalId)
                    State.pollCounter = 0
                    State.loading = false
                    State.error = null

                    if (response.data.view) {
                        document.getElementById('chat-component-wrapper').innerHTML = response.data.view
                    }

                    if (response.data.tcpaData) {
                        Alpine.store('qv').tcpa.set(response.data.tcpaData)
                    }

                }

            }

            if (State.pollCounter >= State.slowResponseTimeThresholdSeconds) {
                State.error = 'Oops, it\'s taking us a little longer to respond...'
            }

        }, 1000)

    }

    randomDelay() {

        const milliseconds = Math.floor(Math.random() * (750 - 500 + 1) + 500)

        return new Promise(resolve => setTimeout(resolve, milliseconds))

    }

    async submitForm() {

        const store = Alpine.store('qv')

        State.submitting = true
        beforeChatConversationUpdated(State.formData, store.metadata)

        const formData = State.formData
        const seenTCPAId = store.tcpa.getRecordableTcpaId()

        if (seenTCPAId) {
            formData.seenTCPAId = seenTCPAId
        }

        const message = {
            id: State.messages.length + 1,
            key: this.randomKey(),
            role: ChatRole.User,
            hasError: false,
            ...formData,
        }

        State.messages.push(message)

        const response = await fetch(`/update-visitor-conversation/${ Alpine.store('state').visitorId }`, {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                accept: 'application/json',
                'content-type': 'application/json',
                'x-csrf-token': Alpine.store('qv').getCsrfToken(),
            },
        })
            .then(response => response.json())
            .then(data => data)

        if (response.success === true) {

            message.id = response.data.message.id
            State.error = null
            await this.randomDelay()
            State.loading = true
            this.pollForMessages(State.lastMessageId)

        } else {

            State.error = 'Looks like we are having some trouble right now. Please try again in a few seconds.'

            setTimeout(() => {

                State.loading = false
                State.error = null

            }, 4000)

        }

        State.submitting = false

    }

    async showWelcomeMessage(currentStep, welcomeMessage) {

        if (State.messages.length) {
            return
        }

        await this.randomDelay()

        State.messages.push({
            id: State.messages.length + 1,
            key: this.randomKey(),
            role: ChatRole.Assistant,
            hasError: false,
            content: welcomeMessage,
        })

        await this.randomDelay()

        State.messages.push({
            id: State.messages.length + 1,
            key: this.randomKey(),
            role: 'assistant',
            hasError: false,
            content: currentStep.content.headline,
            step: currentStep,
        })

        State.loading = false

    }

    handleIncomingMessages(newMessages) {

        for (const message of newMessages) {

            message.key = this.randomKey()
            State.messages.push(message)

        }

    }

    isValidDateFormat(dateString) {

        const dateRegex = /^(0[1-9]|1[0-2])(0[1-9]|1\d|2\d|3[01])(19|20)\d{2}$/

        return dateRegex.test(dateString)

    }

    convertYYYYMMDDtoDDMMYYYYwithMasks(dateString) {

        const parts = dateString.split('/')

        if (parts.length === 3) {

            const y1 = parts[ 0 ].padStart(2, '0')
            const y2 = parts[ 1 ].padStart(2, '0')
            const monthday = parts[ 2 ].padStart(4, '0')
            const month = monthday.substring(0, 2)
            const day = monthday.substring(2, 4)

            return `${ month }/${ day }/${ y1 }${ y2 }`

        }

        return dateString

    }

}()

export default ChatController

export class UrlHelper {

    #url

    constructor(url) {
        this.#url = new URL(url)
    }

    get path() {
        return this.#url.pathname
    }

    get query() {

        const queryParameters = {}

        for (const [ key, value ] of this.#url.searchParams.entries()) {
            queryParameters[ key ] = value
        }

        return queryParameters

    }

    get hash() {
        return this.#url.hash
    }

}

import Alpine from 'alpinejs'

export class Modal {

    canClose = true
    show = false
    showContactUs = false
    content = null
    width = '4xl'
    modals = {}
    modalListeners = []

    pushModal(id, content) {

        this.modals = {
            ...this.modals,
            [ id ]: content,
        }

    }

    get widthClass() {

        switch (this.width) {

        case 'xs':
            return 'sm:max-w-xs'

        case 'sm':
            return 'sm:max-w-sm'

        case 'md':
            return 'sm:max-w-md'

        case 'lg':
            return 'sm:max-w-lg'

        case 'xl':
            return 'sm:max-w-xl'

        case '2xl':
            return 'sm:max-w-2xl'

        case '3xl':
            return 'sm:max-w-3xl'

        case '4xl':
            return 'sm:max-w-4xl'

        default:
            return 'sm:max-w-4xl'

        }

    }

    setModals(links) {

        this.modals = links
            .filter(link => link.type === 'modal')
            .map(link => ({
                [ link.data.id ]: link.data.content,
            }))
            .reduce((accumulator, item) => {

                const [ key, value ] = Object.entries(item)[ 0 ]

                accumulator[ key ] = value

                return accumulator

            }, {})

    }

    bindModalHandlers() {

        for (const element of this.modalListeners) {
            element.removeEventListener('click', event => this.processModalEvent(event))
        }

        this.modalListeners = []

        const modalLinks = Array.from(document.getElementsByClassName('modal-link'))

        for (const element of modalLinks) {

            element.addEventListener('click', event => this.processModalEvent(event))
            this.modalListeners.push(element)

        }

    }

    processModalEvent(event) {

        if (event.target instanceof HTMLElement) {

            const id = event.target.getAttribute('data-modal')

            this.openModal(id)

        }

    }

    openModal(id, config = null) {

        if (id && Object.keys(this.modals).includes(id)) {

            this.content = this.modals[ id ]
            this.showModal(config)

            // Allow modals to call other modals by re-registering the modal handlers after the modal is shown...
            Alpine.nextTick(() => {
                this.bindModalHandlers()
            })

        }

    }

    openModalFromRaw(content, config = null) {

        this.content = content
        this.showModal(config)

    }

    showModal(config = null) {

        if (config) {

            this.width = config?.width || '4xl'
            this.canClose = config?.canClose ?? true

        }

        this.show = true

    }

    openContactUsModal() {
        this.showContactUs = true
    }

    closeModal() {

        if (this.canClose === false) {
            return
        }

        this.show = false

        setTimeout(() => {

            for (const node of document.getElementById('qv-modal-content').children) {
                Alpine.destroyTree(node)
            }

            Alpine.nextTick(() => {

                this.content = null
                this.width = '4xl'

            })

        }, 150)

    }

}

import Alpine from 'alpinejs'
import { ActivePage } from '../../enums/ActivePage'
import State from './State'

let preloadCache

const ThankYouController = new class {

    async preload() {

        if (typeof preloadCache !== 'undefined') {
            return true
        }

        preloadCache = await fetch(appendQuery(`/fast/thank-you/${ Alpine.store('state').visitorId }`))
            .then(response => response.json())
            .then(data => data.data.view)

        return true

    }

    async start() {

        await this.preload()
        State.content = preloadCache
        preloadCache = undefined
        Alpine.store('qv').navigation.activePage = ActivePage.ThankYou
        Alpine.store('qv').navigation.goToThankYouPage()

    }

    async confirm(thankYouPageId) {

        await fetch(`/thank-you/${ Alpine.store('state').visitorId }/${ thankYouPageId }/confirm`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'x-csrf-token': Alpine.store('qv').getCsrfToken(),
            },
        })

    }

    async rotate() {

        await fetch(appendQuery(`/fast/thank-you/${ Alpine.store('state').visitorId }/rotate`))
            .then(response => response.json())
            .then(data => {

                if (data.data && typeof data.data.view !== 'undefined') {
                    State.content = data.data.view
                }

            })

    }

    async updateLeadData(data) {

        await fetch(`/update-lead-data/${ Alpine.store('state').visitorId }`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'x-csrf-token': Alpine.store('qv').getCsrfToken(),
            },
            body: JSON.stringify(data),
        })

    }

}()

function appendQuery(path) {
    return Alpine.store('qv').helpers.appendQuery(path)
}

export default ThankYouController
